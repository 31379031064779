var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "footer_content flex-center flex-column-center" },
        [
          _c("div", { staticClass: "footer_img" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.hrefUrl(_vm.platLogo.url),
                  target: _vm.targetType(_vm.platLogo.url),
                },
              },
              [
                _c("img", {
                  staticClass: "img_left",
                  attrs: {
                    src: _vm.platLogo.footer || _vm.platLogo.img,
                    alt: "",
                  },
                }),
              ]
            ),
            _c(
              "a",
              {
                attrs: {
                  href: _vm.hrefUrl(_vm.gameLogo.url),
                  target: _vm.targetType(_vm.gameLogo.url),
                },
              },
              [
                _c("img", {
                  staticClass: "img_right",
                  attrs: { src: _vm.gameLogo.img, alt: "" },
                }),
              ]
            ),
          ]),
          _c(
            "ul",
            { staticClass: "footer_text" },
            _vm._l(_vm.links, function (item, index) {
              return _c("li", { key: index }, [
                item.email
                  ? _c(
                      "span",
                      {
                        on: {
                          click: () => {
                            _vm.show = true
                            _vm.email = item.email
                            item.handleClick()
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$ps("footer.serviceCenter")) + " "
                        ),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.hrefUrl(item.url),
                          target: _vm.targetType(item.url),
                        },
                        on: { click: item.handleClick },
                      },
                      [_vm._v(" " + _vm._s(_vm.$ps(item.label)) + " ")]
                    ),
              ])
            }),
            0
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "service_tips",
          style: { height: "60%" },
          attrs: { closeable: "", round: "", position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "service_content" }, [
            _c("div", { staticClass: "service_title" }, [
              _vm._v(_vm._s(_vm.$ps("footer.serviceCenter"))),
            ]),
            _c("div", { staticClass: "service_con" }, [
              _c("div", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.$ps("footer.emailTips"))),
              ]),
              _c("div", { staticClass: "email" }, [_vm._v(_vm._s(_vm.email))]),
            ]),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.email,
                    expression: "email",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: _vm.copySuccess,
                    expression: "copySuccess",
                    arg: "success",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:fail",
                    value: _vm.copyFail,
                    expression: "copyFail",
                    arg: "fail",
                  },
                ],
                ref: "copyBUtton",
                staticClass: "service_button",
                attrs: { href: "javascript:;" },
              },
              [_vm._v(" " + _vm._s("button2") + " ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }