<template>
    <component :is="isMobile ? 'FooterM' : 'FooterPc'" :gameLogo="gameLogo" :platLogo="platLogo" :links="links">
    </component>
</template>

<script>
import { mapGetters } from 'vuex'
import { getGameLogoByUrl, getGamePlatLogoByUrl } from '@/utils/game'
import FooterPc from './Pc'
import FooterM from './M'
import { logPolicy1, logPolicy2, logPolicy3, logCustomerCenter } from '@/utils/log'

export default {
    name: 'LayoutFooter',
    components: {
        FooterPc,
        FooterM
    },
    computed: {
        ...mapGetters(['isMobile', 'lang']),
        gameLogo() {
            return {
                img: getGameLogoByUrl(this.lang)
            }
        }
    },
    data() {
        return {
            platLogo: {
                img: getGamePlatLogoByUrl()
            },
            links: [
                {
                    label: 'footer.termsOfService',
                    url: 'https://www.haikyu-flyhigh.jp/copyright',
                    handleClick: logPolicy1
                },
                {
                    label: 'footer.privacyPolicy',
                    url: 'https://www.haikyu-flyhigh.jp/fund/index.html',
                    handleClick: logPolicy2
                },
                {
                    label: 'footer.officialFanGroup',
                    url: 'https://www.haikyu-flyhigh.jp/about/index.html',
                    handleClick: logPolicy3
                },
                {
                    label: 'footer.serviceCenter',
                    url: 'https://www.haikyu-flyhigh.jp/consult',
                    handleClick: logCustomerCenter
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped></style>