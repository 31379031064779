var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page_content" }, [
    _c(
      "div",
      { staticClass: "footer_content flex-center flex-column-center" },
      [
        _c("div", { staticClass: "footer_img" }, [
          _c(
            "a",
            {
              attrs: {
                href: _vm.hrefUrl(_vm.gameLogo.url),
                target: _vm.targetType(_vm.gameLogo.url),
              },
            },
            [
              _c("img", {
                staticClass: "img_left",
                attrs: { src: _vm.gameLogo.img, alt: "" },
              }),
            ]
          ),
        ]),
        _c(
          "ul",
          { staticClass: "footer_text" },
          _vm._l(_vm.links, function (item, index) {
            return _c(
              "li",
              { key: index },
              [
                item.email
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            _vm.$ps("footer.emailTips") + " " + item.email,
                          placement: "top-start",
                        },
                      },
                      [
                        _c("span", { on: { click: item.handleClick } }, [
                          _vm._v(_vm._s(_vm.$ps(item.label))),
                        ]),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.hrefUrl(item.url),
                          target: _vm.targetType(item.url),
                        },
                        on: { click: item.handleClick },
                      },
                      [_vm._v(" " + _vm._s(_vm.$ps(item.label)) + " ")]
                    ),
              ],
              1
            )
          }),
          0
        ),
        _c("div", { staticClass: "footer_img" }, [
          _c(
            "a",
            {
              attrs: {
                href: _vm.hrefUrl(_vm.platLogo.url),
                target: _vm.targetType(_vm.platLogo.url),
              },
            },
            [
              _c("img", {
                staticClass: "img_right",
                attrs: { src: _vm.platLogo.img, alt: "" },
              }),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }