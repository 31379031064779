<template>
  <div>
    <div class="footer_content flex-center flex-column-center">
      <div class="footer_img">
        <a :href="hrefUrl(platLogo.url)" :target="targetType(platLogo.url)">
          <img class="img_left" :src="platLogo.footer || platLogo.img" alt=""/>
        </a>
        <a :href="hrefUrl(gameLogo.url)" :target="targetType(gameLogo.url)">
          <img class="img_right" :src="gameLogo.img" alt=""/>
        </a>
        
      </div>

      <ul class="footer_text">

        <li v-for="(item, index) in links" :key="index">
          <span 
            v-if="item.email" 
            @click="()=>{
              show = true
              email = item.email
              item.handleClick()
            }">
            {{$ps('footer.serviceCenter')}}
          </span>
          <a 
            v-else
            :href="hrefUrl(item.url)" 
            :target="targetType(item.url)"
            @click="item.handleClick"
          >
            {{ $ps(item.label) }}
          </a>
        </li>
      
      </ul>
    </div>

    <van-popup class="service_tips" v-model="show" closeable round position="bottom" :style="{ height: '60%' }" >
      <div class="service_content">
        <div class="service_title">{{$ps('footer.serviceCenter')}}</div>
        <div class="service_con">
          <div class="text">{{$ps("footer.emailTips")}}</div>
          <div class="email">{{ email }}</div>
        </div>
        <a href="javascript:;"
          class="service_button" 
          ref="copyBUtton"
          v-clipboard:copy="email"
          v-clipboard:success="copySuccess"
          v-clipboard:fail="copyFail"
        >
          {{ 'button2' }}
        </a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'
import {tipsSuccess} from "@/utils";
import { mapGetters } from 'vuex'
import mixin from './mixin'

export default {
mixins: [mixin],
data(){
  return {
    show: false,
    email: ''
  }
},
methods:{
  copySuccess() {
    tipsSuccess(this.$sys('warn.copySuccess'))
  },
  copyFail() {
    tipsSuccess('Fail')
  }
},
computed:{
  ...mapGetters(['isMobile']),
},
components: {
  [Popup.name]: Popup
},
watch:{
  isMobile(value){
    if(!value){
      this.show = false;
    }
  }
}
}
</script>

<style lang="scss">
@import '@/style/var.scss';
.device-m {
  .pay_layout_footer_height{
      height: $m-footer-height;
      .pay_layout_footer {
          min-width: 0;
          height: $m-footer-height;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #303030;
          color: #fff;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 100% auto;
          .footer_content{
              .footer_img{
                  margin: 20px 0;
                  .img_left{
                      height: 40px;
                  }
                  .img_right{
                      height: 40px;
                      margin-left: 20px;
                      margin-top: -5px;
                  }
              }

              .footer_text{
                  font-size: 14px;
                  margin-bottom: 10px;
                  display: flex;
                  > li {
                    margin: 0 5px;
                    &:not(:last-child) {
                      &::after {
                        content: '/';
                        margin-left: 10px;
                        color: #b6b6b6;
                      }
                    }
                  }
                  .margin{
                    margin: 0 10px;
                  }
                  a{
                    color: #b6b6b6;
                    cursor: pointer;
                    &:hover{
                      color: #fff;
                    }
                  }
                  span{
                    color: #b6b6b6;
                    cursor: pointer;
                    &:hover{
                      color: #fff;
                    }
                  }
              }
          }
          .service_tips{
            .service_content{
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .service_title{
                height: 100px;
                line-height: 100px;
                font-weight: bold;
                color: #000;
              }
              .service_con{
                width: 100%;
                padding: 20px;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                .text{
                  width: 100%;
                  color: #9B9B9B;
                }
                .email{
                  color: #BD2A2A;
                  font-size: 45px;
                  font-weight: bold;
                  margin-top: 15px
                }
              }
              .service_button{
                height: 80px;
                line-height: 80px;
                text-align: center;
                border-radius: 40px;
                background-color: #DF2D2D;
                margin: auto;
                color: #fff;
                padding: 0 20px;
              }
            }
          }
      }
  }
}
</style>