<template>
  <component :is="isMobile ? 'layoutM' : 'layoutPc'">

    <Header>
      <div class="flex-center">
        <a :href="gameLink || 'javascript:;'" :target="gameLink ? '_blank' : '_self'">
          <img :src="gameLogo" alt="" class="logo">
        </a>
      </div>
    </Header>

    <Content class="page_main">
      <router-view v-if='isInitFinish'></router-view>
    </Content>

    <Footer>
      <PsFooter />
    </Footer>

    <PopHelp />

    <PopLogin />

    <PopSaveDesktop />

  </component>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { setFavicon, urlQuery, formatDateTime, deleteTicket, deleteSource } from '@/utils'
import { getGameNameByUrl, getGameLogoByUrl, getGameLinkByUrl } from '@/utils/game'
import { getSource, setSource } from '@/utils/storage'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Content from '@/components/Content'
import layoutPc from '../device/Pc'
import layoutM from '../device/M'
import PsFooter from '../components/Footer'
import PopHelp from '../components/PopHelp'
import PopLogin from '../components/PopLogin'
import PopSaveDesktop from "@/pages/ps/components/PopSaveDesktop/index.vue";

export default {
  components: {
    PopSaveDesktop,
    Footer,
    layoutPc,
    layoutM,
    Header,
    Content,
    PsFooter,

    PopHelp,
    PopLogin
  },
  data() {
    return {
      gameName: getGameNameByUrl(),
      langs: []
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isInitFinish', 'isMaintain', 'isLogin', 'isRole', 'lang']),
    ...mapState('game', ['country', 'lang', 'gameInfo']),
    status() {
      return [this.isMaintain, this.isLogin, this.isRole, this.isInitFinish]
    },
    gameLogo() {
      return getGameLogoByUrl(this.lang)
    },
    gameLink() {
      return getGameLinkByUrl(this.lang)
    }
  },
  async created() {
    setFavicon(process.env.VUE_APP_STATIC_PATH + 'static/ps-favicon.ico')

    let { usersource, ticket } = urlQuery()

    if (usersource) setSource(usersource) // ingame、officialwebsite、others

    try {

      await this.gameRegionLang()

      if(ticket && usersource != 'ingame') {
        deleteTicket()
        await this.loginByTicket(ticket)
      }
      await this.gameInit()
      await this.initUser()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    formatDateTime,
    ...mapMutations('game', ['SET_STEP_NAME']),
    ...mapActions('game', ['gameInit', 'gameRegionLang', 'chooseLang', 'chooseCountry']),
    ...mapActions('user', ['loginByTicket', 'getUserInfo']),
    async initUser() {

      deleteSource()  // 这里不是清缓存，是删除 url 参数

      await this.getUserInfo()
    }
  },
  watch: {
    status() {
      if (this.$route.name !== 'psIndex' && (!this.isLogin || this.isMaintain)) {
        this.$router.push('index')
      }
      if (this.isInitFinish) {
        this.SET_STEP_NAME(this.isLogin && !this.isRole ? 'role' : 'mall')
      }
    }
  }
}
</script>

<style lang='scss'></style>