<template>
  <Popup 
    :value="isPopLogin" 
    :width="isMobile ? '95%' : '540px'" 
    @close="() => SET_IS_POP_LOGIN(false)" 
    :modelClose="false">
    <iframe 
      width="100%"
      :height="isMobile ? popHeight : 300"
      scrolling="no" frameborder="0" 
      :src="loginUrl" id="loginIframe"
      allowtransparency="true" 
      @load="loadEnd()">
    </iframe>
  </Popup>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { apiLoginUrl } from '@/api/user'
import { Dialog, Icon } from 'vant'
import Popup from "@/components/Popup"

export default {
    name: 'Login',
    components: {
      Popup,
      'van-dialog': Dialog.Component,
      [Icon.name]: Icon,
    },
    data() {
        return {
            loading: true
        }
    },
    computed: {
        ...mapGetters(['isMobile', 'lang']),
        ...mapState('user', ['isPopLogin', 'clientWidth']),
        loginUrl() {
            return apiLoginUrl(this.lang)
        },
        popHeight () {
          if(this.clientWidth < 550){
            return 270
          }else if(this.clientWidth >550 && this.clientWidth <= 1000){
            return 340
          }else{
            return 440
          }
        }
    },
    methods: {
        ...mapMutations('user', ['SET_IS_POP_LOGIN']),
        loadEnd() {
            this.loading = false
        }
    }
}
</script>
<style lang="scss">

</style>