<template>
    <div class="device-pc">

        <slot></slot>

    </div>
</template>

<script>

export default {
    
}
</script>

<style lang="scss">
@import '@/style/page/ps/pc-index';

</style>