<template>
  <div class="page_content">
    <div class="footer_content flex-center flex-column-center">
      <div class="footer_img">
        <a :href="hrefUrl(gameLogo.url)" :target="targetType(gameLogo.url)">
          <img class="img_left" :src="gameLogo.img" alt=""/>
        </a>
      </div>

      <ul class="footer_text">
        <li v-for="(item, index) in links" :key="index">
          <el-tooltip
            v-if="item.email"
            :content="$ps('footer.emailTips') + ' ' + item.email" placement="top-start">
            <span @click="item.handleClick">{{ $ps(item.label) }}</span>
          </el-tooltip>
          <a 
            v-else
            :href="hrefUrl(item.url)" 
            :target="targetType(item.url)"
            @click="item.handleClick"
          >
            {{ $ps(item.label) }}
          </a>
          
        </li>
      </ul>
      <div class="footer_img">
        <a :href="hrefUrl(platLogo.url)" :target="targetType(platLogo.url)">
          <img class="img_right" :src="platLogo.img" alt=""/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {Tooltip} from 'element-ui'
import mixin from './mixin'

export default {
mixins: [mixin],
components:{
  [Tooltip.name]: Tooltip,
}
}
</script>

<style lang="scss">
@import '@/style/var.scss';

.device-pc {
  .pay_layout_footer_height{
    height: $pc-footer-height;
    .pay_layout_footer {
      min-width: $pc-content-width;
      height: $pc-footer-height;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #303030;
      color: #fff;
      background-repeat: no-repeat;
      background-position: center center;
      .footer_content{
        .footer_img{
          margin-top: 10px;
          .img_left{
            height: 40px;
          }
          .img_right{
            height: 40px;
          }
          margin-bottom: 10px;
        }

        .footer_text{
          margin-bottom: 5px;
          font-size: 14px;
          margin-top: -5px;
          display: flex;
          > li {
            margin: 0 5px;
            &:not(:last-child) {
              &::after {
                content: '/';
                margin-left: 10px;
              }
            }
          }
          a{
            color: #b6b6b6;
            cursor: pointer;
            &:hover{
              color: #fff;
            }
          }
          span{
            color: #b6b6b6;
            cursor: pointer;
            &:hover{
              color: #fff;
            }
          }
          .item{
            margin: 4px;
          }
          .margin{
            margin: 0 10px;
          }
        }

      }
    }
  }
}



</style>