import { render, staticRenderFns } from "./PopHelp.vue?vue&type=template&id=7c63b451&scoped=true"
import script from "./PopHelp.vue?vue&type=script&lang=js"
export * from "./PopHelp.vue?vue&type=script&lang=js"
import style0 from "./PopHelp.vue?vue&type=style&index=0&id=7c63b451&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c63b451",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/gitlab-runner/builds/mrZpYrznL/0/maggie-oversea/maggie-oversea-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c63b451')) {
      api.createRecord('7c63b451', component.options)
    } else {
      api.reload('7c63b451', component.options)
    }
    module.hot.accept("./PopHelp.vue?vue&type=template&id=7c63b451&scoped=true", function () {
      api.rerender('7c63b451', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/ps/components/PopHelp.vue"
export default component.exports