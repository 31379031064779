<template>
    <Popup 
        v-model="isPopHelp" 
        @close="SET_IS_POP_HELP(false)">
        <div class="help_info" v-html="$ps('user.helpContent')"></div>
    </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { mapState, mapMutations } from 'vuex'

export default {
    components: {
        Popup
    },
    computed: {
        ...mapState('user', ['isPopHelp'])
    },
    methods: {
        ...mapMutations('user', ['SET_IS_POP_HELP'])
    }
}
</script>

<style lang="scss" scoped>
.help_info {
    line-height: 24px;
    word-break: normal;
    padding: 0 20px;
    :deep p + p{
      margin-bottom: 15px;
    }
    :first-child {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }
}
</style>